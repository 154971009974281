import React, { useState } from 'react';
import { camelCase, isEmpty } from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';

import SigninFormComponent, {
  SigninFormData,
  SigninFormErrors,
} from '../components/SigninForm';
import { AuthenticateUserMutation } from '../../../api/graphql/mutations';
import { BasicError } from '../../../api/graphql/types';
import useAuthenticateUser from '../hooks/useAuthenticateUser';
import fireEvent from '../../../../analytics/fireEvent';
import { appSignIn } from '../analytics/events';

export default function SigninForm() {
  const [errors, setErrors] = useState<SigninFormErrors>([]);
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const onAuthenticateUserCompleted = (data: AuthenticateUserMutation) => {
    const requestErrors = data.authenticateUser?.errors;
    const noErrors = !requestErrors || isEmpty(requestErrors);
    const userStatus = data.authenticateUser?.user?.status;
    const activeUser = userStatus === 'active';

    if (noErrors && activeUser) {
      fireEvent(appSignIn);

      const redirectTo = searchParams.get('redirect_to');

      if (redirectTo) {
        window.location.replace(`/${redirectTo}`);
        return;
      }

      window.location.replace(`/${search}`);
      return;
    }

    const mappedErrors = mapErrors(requestErrors);
    const inactiveUserError =
      userStatus === 'inactive'
        ? ([['login', 'user.login.errors.inactiveUser']] as SigninFormErrors)
        : [];

    setErrors([...mappedErrors, ...inactiveUserError]);
  };
  const { loading, authenticate } = useAuthenticateUser({
    onCompleted: onAuthenticateUserCompleted,
  });

  const onSubmit = ({ login, password }: SigninFormData) => {
    authenticate({ login: formatLogin(login), password });
  };

  return (
    <SigninFormComponent
      errors={errors}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
}

const mapErrors = (errors?: BasicError[] | null) => {
  if (!errors) return [];

  return errors
    .filter((error) => !!error.path && !isEmpty(error.path))
    .map(({ path }) => {
      const field = camelCase((path as string[])[0]) as keyof SigninFormData;

      return [field, 'user.login.errors.wrongCredentials'];
    }) as SigninFormErrors;
};

const formatLogin = (login: string) => {
  if (login.includes('@')) return login;

  return login.replace(/[-() .]/g, '');
};
